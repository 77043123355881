<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Pievienot darbinieku"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Darbinieks tika veiksmīgi pievienots!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p v-for="(e, index) in errors" :key="index"> {{ e[0] }} </p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" enctype="multipart/form-data">

            <div class="group-title">
              <b-button v-b-toggle.collapse-1 squared variant="primary">Slēpt/Rādīt</b-button>
              Personas dati:
            </div>

            <b-collapse id="collapse-1" class="mt-2">
              <b-form-group
                  id="input-group-1"
                  label="Vārds:"
                  label-for="input-1"
              >

                <b-form-input id="input-1"
                              v-model="$v.form.name.$model"
                              :state="validateState('name')"
                              aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-1-live-feedback"
                >Nepareizi ievadīts vārds</b-form-invalid-feedback>

              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Uzvārds:"
                  label-for="input-2"
              >

                <b-form-input id="input-2"
                              v-model="$v.form.surname.$model"
                              :state="validateState('surname')"
                              aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-2-live-feedback"
                >Nepareizi ievadīts uzvārds</b-form-invalid-feedback>

              </b-form-group>

              <b-form-group
                  id="input-group-3"
                  label="Personas kods:"
                  label-for="input-3"
              >

                <b-form-input id="input-3"
                              v-model="$v.form.personal_code.$model"
                              :state="validateState('personal_code')"
                              aria-describedby="input-3-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-3-live-feedback"
                >Nepareizi ievadīts personas kods</b-form-invalid-feedback>

              </b-form-group>

              <b-form-group
                  id="input-group-3"
                  label="Dzimšanas datums:"
                  label-for="input-3"
              >

                <b-form-input id="input-3"
                              v-model="$v.form.birthdate.$model"
                              type="date"
                              :state="validateState('birthdate')"
                              aria-describedby="input-3-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-3-live-feedback"
                >Nepareizi ievadīts dzimšanas datums</b-form-invalid-feedback>

              </b-form-group>

              <b-form-group
                  id="input-group-4"
                  label="Tālrunis:"
                  label-for="input-4"
              >

                <b-form-input type="text" v-model="form.phone"></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-5"
                  label="E-pasts:"
                  label-for="input-5"
              >

                <b-form-input id="input-5"
                              type="email"
                              v-model="$v.form.email.$model"
                              :state="validateState('email')"
                              aria-describedby="input-5-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-5-live-feedback"
                >Nepareizi ievadīts E-pasts</b-form-invalid-feedback>

              </b-form-group>

              <b-form-group
                  id="input-group-6"
                  label="Dzimums:"
                  label-for="input-6"
                  v-model="$v.form.gender.$model"
                  :state="validateState('gender')"
                  aria-describedby="input-6-live-feedback"
              >

                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="$v.form.gender.$model" :state="validateState('gender')"
                                aria-describedby="input-6-live-feedback" value="0">sieviete</b-form-radio>
                  <b-form-radio v-model="$v.form.gender.$model" :state="validateState('gender')"
                                aria-describedby="input-6-live-feedback" value="1">vīrietis</b-form-radio>
                </b-form-group>

              </b-form-group>

              <b-form-group
                  id="input-group-1"
                  label="Līguma nr.:"
                  label-for="input-1"
              >

                <b-form-input id="input-1"
                              v-model="$v.form.agreement.$model"
                              :state="validateState('agreement')"
                              aria-describedby="input-3-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-3-live-feedback"
                >Nepareizi ievadīts līguma nr.</b-form-invalid-feedback>

              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Līguma datums:"
                  label-for="input-2"
              >

                <b-form-input id="input-2"
                              v-model="$v.form.agreement_date.$model"
                              type="date"
                              :state="validateState('agreement_date')"
                              aria-describedby="input-3-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                    id="input-3-live-feedback"
                >Nepareizi ievadīts līguma datums</b-form-invalid-feedback>

              </b-form-group>

            </b-collapse>

            <div class="group-title">
              <b-button v-b-toggle.collapse-2 squared variant="primary">Slēpt/Rādīt</b-button>
              Izglītība:
            </div>

            <b-collapse id="collapse-2" class="mt-2">
              <b-form-group
                  id="input-group-1"
                  label="Izglītības veids:"
                  label-for="input-1"
                  v-if="options"
              >

                <b-form-input list="my-list-id" v-model="form.education_type"></b-form-input>

                <datalist id="my-list-id">
                  <option v-for="et in options.education_types.data" :id="et">{{ et.text }}</option>
                </datalist>

              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Grāds:"
                  label-for="input-2"
                  v-if="options"
              >

                <b-form-input list="my-list-id2" v-model="form.grade"></b-form-input>

                <datalist id="my-list-id2">
                  <option v-for="et in options.grades.data" :id="et">{{ et.text }}</option>
                </datalist>

              </b-form-group>

              <b-form-group
                  id="input-group-3"
                  label="Kvalifikācija:"
                  label-for="input-3"
                  v-if="options"
              >

                <b-form-input list="my-list-id3" v-model="form.qual"></b-form-input>

                <datalist id="my-list-id3">
                  <option v-for="et in options.quals.data" :id="et">{{ et.text }}</option>
                </datalist>

              </b-form-group>

              <b-form-group label="Pedagoģiskā izglītība:" id="radio-1" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="form.courses" :aria-describedby="ariaDescribedby" value="1">Ir nepieciešams</b-form-radio>
                <b-form-radio v-model="form.courses" :aria-describedby="ariaDescribedby" value="0">Nav nepieciešams</b-form-radio>
              </b-form-group>

            </b-collapse>

            <div class="group-title">
              <b-button v-b-toggle.collapse-8 squared variant="primary">Slēpt/Rādīt</b-button>
              Kategorija:
            </div>

            <b-collapse id="collapse-8" class="mt-2">

              <b-container>

                <b-form-group label="Kategorija:" id="radio-5" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.category" :aria-describedby="ariaDescribedby" name="some-radios5" value="0">darbinieks</b-form-radio>
                  <b-form-radio v-model="form.category" :aria-describedby="ariaDescribedby" name="some-radios6" value="1">speciālists</b-form-radio>
                  <b-form-radio v-model="form.category" :aria-describedby="ariaDescribedby" name="some-radios7" value="2">vadītājs</b-form-radio>
                </b-form-group>

              </b-container>


            </b-collapse>

            <div class="group-title">
              <b-button v-b-toggle.collapse-3 squared variant="primary">Slēpt/Rādīt</b-button>
              Darbs:
            </div>

            <b-collapse id="collapse-3" class="mt-2">

              <b-form-group
                  id="input-group-3"
                  label="Status:"
                  label-for="input-3"
              >

                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.status" :aria-describedby="ariaDescribedby" value="0">Pedagoģiskais darbinieks</b-form-radio>
                  <b-form-radio v-model="form.status" :aria-describedby="ariaDescribedby" value="1">Tehniskais darbinieks</b-form-radio>
                  <b-form-radio v-model="form.status" :aria-describedby="ariaDescribedby" value="2">Tehniskais/Pedagoģiskais darbinieks</b-form-radio>

                </b-form-group>

              </b-form-group>

              <b-container v-for="(pos, index) in $v.form.positions.$each.$iter" :key="index">

                <b-form-group
                    id="input-group-1"
                    label="Struktūrvienība:"
                    label-for="input-1"
                    v-if="options"
                >

                  <b-form-input list="my-list-id4" :class="[pos.department.$dirty ? 'is-valid' : '', pos.department.$error ? 'is-invalid' : '']" v-model.trim="pos.department.$model"
                  aria-describedby="mylist-4-live-feedback"></b-form-input>
                  <b-form-invalid-feedback id="mylist-4-live-feedback"
                  >Nepareizi ievadīta struktūrvienība</b-form-invalid-feedback>

                  <datalist id="my-list-id4">
                    <option v-for="et in options.departments.data" :id="et">{{ et.text }}</option>
                  </datalist>

                </b-form-group>

                <b-form-group
                    id="input-group-23"
                    label="Tiešais vadītājs:"
                    label-for="input-23"
                >
                  <b-form-input
                      id="input-23"
                      list="user-list"
                      v-model="pos.head.$model"
                      aria-describedby="input-23-live-feedback"
                      :state="validatePositions(pos.head)"
                      @input="changeName(pos.head.$model, index)"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="input-1-live-feedback"
                  >Nepareizi ievadīts darbinieka vārds un uzvārds</b-form-invalid-feedback>

                  <datalist id="user-list">
                    <option>Nav</option>
                    <option v-for="user in users" :key="user.id" >{{ user.text }}</option>
                  </datalist>
                </b-form-group>

                <b-form-group
                    id="input-group-2"
                    label="Amats:"
                    label-for="input-2"
                    v-if="options"
                >

                  <b-form-input list="my-list-id5" :class="[pos.post.$dirty ? 'is-valid' : '', pos.post.$error ? 'is-invalid' : '']" v-model.trim="pos.post.$model"
                                aria-describedby="mylist-5-live-feedback"></b-form-input>
                  <b-form-invalid-feedback id="mylist-5-live-feedback"
                  >Nepareizi ievadīts amats</b-form-invalid-feedback>

                  <datalist id="my-list-id5">
                    <option v-for="et in options.positions.data" :id="et">{{ et.text }}</option>
                  </datalist>

                </b-form-group>

                <b-form-group
                    id="input-group3"
                    label="Amata iestāšanās un beigu datums:"
                    label-for="input-3"
                >

                  <b-input-group class="mb-2">

                    <b-form-input type="date" aria-describedby="start-date-live-feedback" :class="[pos.start.$dirty ? 'is-valid' : '', pos.start.$error ? 'is-invalid' : '']" v-model.trim="pos.start.$model"></b-form-input>
                    <b-form-invalid-feedback id="start-date-live-feedback"
                    >Nepareizi ievadits datums</b-form-invalid-feedback>
                    <b-form-input type="date" v-model="pos.end.$model"></b-form-input>

                  </b-input-group>

                </b-form-group>

                <b-form-group
                    id="input-group-3"
                    label="Darba veids:"
                    label-for="input-3"
                >

                  <b-form-group v-slot="{ ariaDescribedby }" :class="[pos.type.$dirty ? 'is-valid' : '', pos.type.$error ? 'is-invalid' : '']">
                    <b-form-radio v-model.trim="pos.type.$model" :aria-describedby="ariaDescribedby" value="0">pamatdarbs</b-form-radio>
                    <b-form-radio v-model.trim="pos.type.$model" :aria-describedby="ariaDescribedby" value="2">papildus darbs</b-form-radio>
                    <b-form-radio v-model.trim="pos.type.$model" :aria-describedby="ariaDescribedby" value="1">blakus darbs</b-form-radio>
                  </b-form-group>
                  <b-form-invalid-feedback id="darbs-live-feedback"
                  >Nav izvēlēts darba veids</b-form-invalid-feedback>

                </b-form-group>

                <hr>

              </b-container>

              <b-col cols="12" class="text-right">
                <b-button-group>
                  <b-button variant="success" @click="addPost">+</b-button>
                  <b-button variant="danger" @click="removePost">-</b-button>
                </b-button-group>
              </b-col>

            </b-collapse>

            <div class="group-title">
              <b-button v-b-toggle.collapse-4 squared variant="primary">Slēpt/Rādīt</b-button>
              Kursi:
            </div>

            <b-collapse id="collapse-4" class="mt-2">

              <b-container>

                <b-form-group label="Audzināšanas stundas:"
                        id="radio-1"
                        v-model="$v.form.edu.$model"
                        :state="validateState('edu')"
                        aria-describedby="input-1-live-feedback"
                    >
                      <b-form-radio v-model="$v.form.edu.$model" :state="validateState('edu')" aria-describedby="input-1-live-feedback"
                                    name="some-radios1" value="1">Ir nepieciešams</b-form-radio>
                      <b-form-radio v-model="$v.form.edu.$model" :state="validateState('edu')" aria-describedby="input-1-live-feedback"
                                    name="some-radios1" value="0">Nav nepieciešams</b-form-radio>
                  </b-form-group>

                <b-form-group label="Bērnu tiesības 8h:"
                              id="radio-2"
                              v-model="$v.form.rights.$model"
                              :state="validateState('rights')"
                              aria-describedby="input-2-live-feedback"
                >
                  <b-form-radio v-model="$v.form.rights.$model" :state="validateState('rights')" aria-describedby="input-2-live-feedback"
                                name="some-radios2" value="1">Ir nepieciešams</b-form-radio>
                  <b-form-radio v-model="$v.form.rights.$model" :state="validateState('rights')" aria-describedby="input-2-live-feedback"
                                name="some-radios2" value="0">Nav nepieciešams</b-form-radio>
                </b-form-group>

                <b-form-group label="Kursi 36h:"
                              id="radio-3"
                              v-model="$v.form.short_courses.$model"
                              :state="validateState('short_courses')"
                              aria-describedby="input-3-live-feedback"
                >
                  <b-form-radio v-model="$v.form.short_courses.$model" :state="validateState('short_courses')" aria-describedby="input-3-live-feedback"
                                name="some-radios3" value="1">Ir nepieciešams</b-form-radio>
                  <b-form-radio v-model="$v.form.short_courses.$model" :state="validateState('short_courses')" aria-describedby="input-3-live-feedback"
                                name="some-radios3" value="0">Nav nepieciešams</b-form-radio>
                </b-form-group>

              </b-container>


            </b-collapse>

            <div class="group-title">
              <b-button v-b-toggle.collapse-5 squared variant="primary">Slēpt/Rādīt</b-button>
              Komentārs:
            </div>

            <b-collapse id="collapse-5" class="mt-2">

              <b-container>

                <b-form-group label="Komentārs:">
                  <b-form-textarea
                      id="textarea"
                      v-model="form.comment"
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                </b-form-group>

              </b-container>


            </b-collapse>

            <div class="group-title">
              <b-button v-b-toggle.collapse-6 squared variant="primary">Slēpt/Rādīt</b-button>
              Sistēmas lomas:
            </div>

            <b-collapse id="collapse-6" class="mt-2">

              <b-container>

                <b-form-group label="Lomas:">

                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="$v.form.roles.$model"
                      :state="validateState('roles')"
                      aria-describedby="input-2-live-feedback"
                  >
                    <b-form-checkbox :value="r.name" v-for="r in roles" :key="r.id">{{ r.name }}</b-form-checkbox>
                  </b-form-checkbox-group>
                  <b-form-invalid-feedback
                      id="input-2-live-feedback"
                  >Nav izvēlēta loma</b-form-invalid-feedback>
                </b-form-group>

              </b-container>

            </b-collapse>

            <b-button type="submit" variant="success">Saglabāt</b-button>
            <b-button variant="warning" class="ml-1" :to="{ name: 'UserList' }">Atpakaļ</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jsonToFormData from '@ajoelp/json-to-formdata';
import {mapActions, mapGetters} from "vuex";
import { validationMixin } from 'vuelidate'
import {email, maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
  name: "Create",
  data() {
    return {
      users: [],
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      errors: null,
      education_types: [],
      grades: [],
      quals: [],
      departments: [],
      positions: [],
      roles: [],
      form: {
        name: null,
        surname: null,
        personal_code: null,
        email: null,
        roles: [],
        birthdate: '',
        phone: '',
        education_type: '',
        grade: '',
        gender: '',
        qual: '',
        edu: null,
        rights: null,
        courses: null,
        comment: '',
        agreement: '',
        agreement_date: '',
        category: '',
        status: '',
        short_courses: '',
        positions: [
          {
            department: '',
            post: '',
            start: '',
            end: '',
            type: '',
            head: '',
            head_id: ''
          },
        ],
      },
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      personal_code: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(12),
      },
      email: {
        required,
        email
      },
      roles: {
        required,
      },
      edu: {
        required,
      },
      rights: {
        required,
      },
      short_courses: {
        required,
      },
      gender: {
        required
      },
      agreement: {
        required
      },
      birthdate: {
        required
      },
      agreement_date: {
        required
      },
      positions: {
        $each: {
          department: {
            required
          },
          post: {
            required
          },
          start: {
            required
          },
          end: {

          },
          type: {
            required
          },
          head_id: {
          },
          head: {
          }
        },
      },
    }
  },

  mounted() {
    this.fetchOptions()
    this.getRoles()
  },
  computed: {
    ...mapGetters ({
      options: "Options/options",
    })
  },
  created () {
    axios.get('/users').then(response => {
      response.data.data.forEach((user) => {
        this.users.push({ text: user.name + ' ' + user.surname , value: user.id })
      });
    })
  },
  methods: {
    ...mapActions ({
      fetchOptions: "Options/fetchOptions"
    }),
    changeName(value, index) {
      if(value !== 'Nav') {
        let user = (this.users).find((e) => e.text === value)
        if(user !== undefined) {
          this.form.positions[index].head_id = user.value
        }
      } else {
        this.form.positions[index].head_id = null
        this.form.positions[index].head = 'Nav'
      }
    },
    addPost() {
      this.form.positions.push({
        department: '',
        post: '',
        start: '',
        end: '',
        type: '',
        head_id: '',
        head: ''
      });
    },
    removePost() {
      this.form.posts.pop();
    },
    onSubmit(event) {
      event.preventDefault()

      this.errors = [];

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors.push('Nav aizpildīti visi lauki')
        window.scrollTo(0, 0);
        return;
      }

      axios.post('/users', jsonToFormData(this.form)).then(response => {

        this.created = 'success';
        //
        // this.form.name = null
        // this.form.surname = null
        // this.form.personal_code = null
        // this.form.email = null
        // this.form.role = 'Skolotājs'

      }).catch(e => {
        this.created = 'error';
        this.errors = e.response.data.errors
      })

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      this.dismissCountDown = 5;
    },
    getRoles() {
      axios.get('/roles').then(response => {
        this.roles = response.data.data;
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    validateState(name) {
      // console.log(name)

      const {$dirty, $error} = this.$v.form[name];

      return $dirty ? !$error : null;
    },
    validatePositions(object) {
      // console.log(object)

      const {$dirty, $error} = object;

      return $dirty ? !$error : null;
    }
  },
}
</script>

<style lang="scss" >
  .group-title {
    border-bottom: 1px solid #e5e5e5;
    font-size: 24px;
  }
</style>